import { useTranslation } from "react-i18next";
import { Button, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../../api/api";
import MyInput from "../../components/Input/MyInput";
import { get, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as SaveICon } from "../../assets/icons/table/save.svg";
import { useParams } from "react-router-dom";
import { getMe } from "../../api/apiFunctions";
import { useQuery } from "react-query";
import Loading from "../../components/Loading/Loading";
import * as yup from "yup";

const ChangePhoto = () => {
    const { t } = useTranslation();

    //   const userIdPhoto = useParams();

    const getUserPhoto = useQuery("userPhoto", getMe);

    // const schema = yup
    //   .object({
    //     image: yup.string().required(),
    //   })
    //   .required();

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        // api
        const id = getUserPhoto?.data?.id;

        const userDetials = getUserPhoto?.data;

        const myImage = data?.image[0];

        let formData = new FormData();

        const myForm = { ...userDetials, image: myImage };

        console.log(myForm, "myForm");

        Object.keys(myForm).forEach((key) => {
            formData.append(key, myForm[key]);
        });

        await api
            .post(`/users/${id}`, formData)
            .then((res) => {
                toast.success(`${t("success.editNumber")}`);
                console.log(res, "res");
            })
            .catch((err) => {
                // console.log(typeof err.response.data.error, "ssss");
                if (typeof err?.response?.data?.error !== "string") {
                    for (let i in err?.response?.data.error) {
                        toast.error(`${err?.response.data.error[i]}`);
                    }
                } else {
                    toast.error(err?.response.data.error);
                }
                console.log(err, "err");
            });
    };

    if (getUserPhoto?.isLoading) {
        return <Loading />;
    }

    return (
        <div className="box">
            <div className="text-center mb-5">
                <img
                    width="180px"
                    height="180px"
                    src={`https://tahhsen.alqimma.info/${getUserPhoto?.data?.image}`}
                    alt="ss"
                    className="rounded-circle"
                    loading="lazy"
                />
            </div>

            <form
                id="update-Employees"
                className="m-4 row m-auto w-50"
                onSubmit={handleSubmit(onSubmit)}
            >
                <MyInput
                    label={t("table.image")}
                    type="file"
                    registered={{
                        ...register("image"),
                    }}
                    error={errors?.image?.message}
                    className="m-auto w-50"
                />
                <Button
                    variant="primary"
                    type="submit"
                    className="m-auto w-50 mb-3"
                >
                    <SaveICon width="22px" height="22px" className="mie" />
                    {t("save")}
                </Button>
            </form>
        </div>
    );
};

export default ChangePhoto;
