import { useTranslation } from "react-i18next";
import MyInput from "../../components/Input/MyInput";
import { useForm } from "react-hook-form";
import { schema } from "./YupSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import api from "../../api/api";

const CategoryForm = ({ modalData, handleclose, fetchUsers }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        // api
        const { id } = modalData;

        await api
            .post(`/categories/${id}`, data)
            .then((res) => {
                fetchUsers();
                toast.success(`${t("success.editNumber")}`);
                console.log(res, "res");
            })
            .catch((err) => {
                if (typeof err?.response?.data?.error !== "string") {
                    for (let i in err?.response?.data.error) {
                        toast.error(`${err?.response.data.error[i]}`);
                    }
                } else {
                    toast.error(err.response.data.error);
                }
                console.log(err, "err");
            });

        handleclose();
    };

    return (
        <form
            id="update-categories"
            className="m-4"
            onSubmit={handleSubmit(onSubmit)}
        >
            <MyInput
                label={t("table.name")}
                type="text"
                defaultValue={modalData?.name}
                registered={{
                    ...register("name"),
                }}
                error={errors.name?.message}
            />
        </form>
    );
};

export default CategoryForm;
