import { useMemo } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import api from "../../api/api";
import Loading from "../../components/Loading/Loading";

const CompanyTable = ({
  modalUserIDSelected,
  tableDataCompanies,
  paginationComponentOptions,
}) => {
  const { t } = useTranslation();

  //  selected list of id permissions users
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      maxWidth: "100px",
    },
    {
      name: "Image",
      selector: (row) => row.image,
      maxWidth: "250px",
      cell: (row) => {
        return (
          <img
            src={`https://tahhsen.alqimma.info/${row.image}`}
            width="70px"
            height="70px"
            alt="test"
          />
        );
      },
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
  ];

  // --- Get permissions users
  const getDataUser = async (modalUserIDSelected) => {
    const data = await api.get(
      `/permission/user/${modalUserIDSelected}?model=Company&length=no`
    );

    return data?.data?.data?.map((el) => el.id);
  };

  const modalUserSelectedQuery = useQuery(
    ["modalUserSelected2", modalUserIDSelected],
    () => getDataUser(modalUserIDSelected),
    {
      cacheTime: 0,
      enabled: !!modalUserIDSelected,
      onSuccess: (data) => {
        setSelectedRows(data);
      },
    }
  );
  // --- Get permissions users

  // row selected users
  let isInitializedUsers = false;
  const handleRowSelectedUsers = (state) => {
    if (state.selectedRows?.length === selectedRows?.length) {
      isInitializedUsers = true;
    }

    if (
      isInitializedUsers === true &&
      state.selectedRows.length !== selectedRows.length
    ) {
      setSelectedRows(state.selectedRows.map((el) => el.id));
    }
  };

  const handleAddUsers = () => {
    api
      .post(`/permission/user/${modalUserIDSelected}`, {
        model: "Company",
        model_ids: selectedRows,
      })
      .then((res) => {
        toast.success(t("addpersuccessfully"));
      })
      .catch((err) => toast.error(err.message));
  };

  // context Actions Users
  const contextActionsUsers = useMemo(() => {
    return (
      <Button
        variant="success"
        key="addUSers"
        onClick={handleAddUsers}
        icon="true"
      >
        {t("addper")}
      </Button>
    );
  }, [selectedRows, t]);

  createTheme("solarized", {
    text: {
      primary: "var(--color-text-primary)",
    },
    background: {
      default: "var(--color-bg-primary)",
      // shadow: "var(--box-shadow)",
    },
  });

  // row Selected Critera USers
  const rowSelectCriteraUsers = (row) => selectedRows.includes(row.id);

  return (
    <DataTable
      title={
        <Button
          variant="success"
          key="addUSers"
          onClick={handleAddUsers}
          icon="true"
        >
          {t("addper")}
        </Button>
      }
      columns={columns}
      data={tableDataCompanies}
      selectableRows
      contextActions={contextActionsUsers}
      onSelectedRowsChange={handleRowSelectedUsers}
      selectableRowSelected={rowSelectCriteraUsers}
      paginationComponentOptions={paginationComponentOptions}
      pagination
      progressPending={modalUserSelectedQuery?.isLoading ? true : false}
      progressComponent={<Loading />}
      theme="solarized"
    />
  );
};

export default CompanyTable;
