import { useTranslation } from "react-i18next";
import MyInput from "../../components/Input/MyInput";
import { useForm } from "react-hook-form";
import { schema } from "./YupSchema2";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Col, Form, Row } from "react-bootstrap";
import api from "../../api/api";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";

const EmployeesForm = ({ modalData, handleclose, refetch }) => {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(new Date(modalData.expiry_date));

    var year = startDate.toLocaleString("default", { year: "numeric" });
    var month = startDate.toLocaleString("default", { month: "2-digit" });
    var day = startDate.toLocaleString("default", { day: "2-digit" });

    var formattedDate = year + "-" + month + "-" + day;

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    console.log(errors, "errors");

    const onSubmit = async (data) => {
        console.log(data, "datadatadatadata");
        Object.keys(data).forEach((key) => {
            if (data[key] === "" || data[key] == null) {
                delete data[key];
            }
        });

        // api
        const { id } = modalData;

        if (data.disabled === true) {
            data.disabled = 1;
        } else {
            data.disabled = 0;
        }

        if (data.admin === true) {
            data.admin = 1;
        } else {
            data.admin = 0;
        }

        if (data.manager === true) {
            data.manager = 1;
        } else {
            data.manager = 0;
        }

        if (data.supervisor === true) {
            data.supervisor = 1;
        } else {
            data.supervisor = 0;
        }

        if (data.read_only === true) {
            data.read_only = 1;
        } else {
            data.read_only = 0;
        }

        const myImage = data?.image[0];

        let myForm;

        if (data?.image.length > 0) {
            myForm = { ...data, expiry_date: formattedDate, image: myImage };
        } else {
            myForm = { ...data, expiry_date: formattedDate };
            delete myForm.image;
        }

        let formData = new FormData();

        Object.keys(myForm).forEach((key) => {
            formData.append(key, myForm[key]);
            console.log(key, myForm[key]);
        });

        await api
            .post(`/users/${id}`, formData)
            .then((res) => {
                refetch();
                toast.success(`${t("success.editNumber")}`);
                console.log(res, "res");
            })
            .catch((err) => {
                if (typeof err?.response?.data?.error !== "string") {
                    for (let i in err?.response?.data.error) {
                        toast.error(`${err?.response.data.error[i]}`);
                    }
                } else {
                    toast.error(err.response.data.error);
                }
                console.log(err, "err");
            });

        handleclose();
    };

    const years = range(1998, getYear(new Date()) + 1, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    return (
        <form
            id="update-Employees"
            className="m-4 row"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="text-center mb-5">
                <img
                    width="80px"
                    height="80px"
                    src={`https://tahhsen.alqimma.info/${modalData.image}`}
                    alt="ss"
                    className="rounded-circle"
                />
            </div>
            <Col sm={6}>
                <MyInput
                    label={t("table.name")}
                    type="text"
                    defaultValue={modalData?.name}
                    registered={{
                        ...register("name"),
                    }}
                    error={errors.name?.message}
                />
            </Col>
            <Col sm={6}>
                <MyInput
                    label={t("table.email")}
                    type="text"
                    defaultValue={modalData?.email}
                    registered={{
                        ...register("email"),
                    }}
                    error={errors.email?.message}
                />
            </Col>
            <Col sm={6}>
                <MyInput
                    label={t("password")}
                    type="password"
                    registered={{
                        ...register("password"),
                    }}
                    error={errors.password?.message}
                />
            </Col>
            <Col sm={6}>
                <MyInput
                    label={t("passwordConfirmation")}
                    type="password"
                    registered={{
                        ...register("password_confirmation"),
                    }}
                    error={errors.password_confirmation?.message}
                />
            </Col>
            <Col sm={6}>
                <MyInput
                    label={t("table.image")}
                    type="file"
                    registered={{
                        ...register("image"),
                    }}
                    error={errors.image?.message}
                />
            </Col>
            <Col sm={6}>
                <Form.Group as={Col} controlId="from" className="selectDate">
                    <Form.Label className="form-label">
                        {t("table.date")}
                    </Form.Label>
                    <DatePicker
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div
                                style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <button
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                    className="btnSelectDate"
                                >
                                    {"<"}
                                </button>

                                <select
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) =>
                                        changeYear(value)
                                    }
                                    className="dropdownSelect"
                                >
                                    {years.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <select
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                    }
                                    className="dropdownSelect"
                                >
                                    {months.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <button
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                    className="btnSelectDate"
                                >
                                    {">"}
                                </button>
                            </div>
                        )}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy/MM/d"
                        // value={modalData.expiry_date}
                        placeholderText="Click to select a date"
                        withPortal
                        required
                    />
                </Form.Group>
            </Col>
            <Col sm={6}>
                <MyInput
                    label={t("trackingUrl")}
                    type="text"
                    defaultValue={modalData?.tracking_url}
                    registered={{
                        ...register("tracking_url"),
                    }}
                    error={errors.tracking_url?.message}
                />
            </Col>
            {/* <Col sm={6}>
        <MyInput
          label={t("trackingToken")}
          type="text"
          defaultValue={modalData?.tracking_token}
          registered={{
            ...register("tracking_token"),
          }}
          error={errors.tracking_token?.message}
        />
      </Col> */}
            <Col sm={6}>
                <MyInput
                    label={t("tracking_ws")}
                    type="text"
                    defaultValue={modalData?.tracking_ws}
                    registered={{
                        ...register("tracking_ws"),
                    }}
                    error={errors.tracking_ws?.message}
                />
            </Col>
            <Col>
                <Row sm={4}>
                    <Form.Check
                        id="Admin"
                        type="checkbox"
                        label="Admin"
                        name="permissions"
                        defaultChecked={modalData?.admin}
                        {...register("admin")}
                    />

                    <Form.Check
                        id="Manager"
                        type="checkbox"
                        label="Manager"
                        name="permissions"
                        defaultChecked={modalData?.manager}
                        {...register("manager")}
                    />
                    <Form.Check
                        id="Supervisor"
                        type="checkbox"
                        label="Supervisor"
                        name="permissions"
                        defaultChecked={modalData?.supervisor}
                        {...register("supervisor")}
                    />
                    <Form.Check
                        id="Read_only"
                        type="checkbox"
                        label="Read_only"
                        name="permissions"
                        defaultChecked={modalData?.read_only}
                        {...register("read_only")}
                    />
                </Row>
                <hr
                    className="my-4"
                    style={{
                        height: "1px",
                        border: "none",
                        backgroundColor: "grey  ",
                        boxShadow: " 0 0 9px 1px grey",
                    }}
                />
                <Row sm={3}>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Disabled"
                        defaultChecked={modalData.disabled}
                        {...register("disabled")}
                    />
                </Row>
            </Col>
        </form>
    );
};

export default EmployeesForm;
