import { useTranslation } from "react-i18next";
import MyInput from "../../components/Input/MyInput";
import { useForm } from "react-hook-form";
import { schema } from "./YupSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Button, Form } from "react-bootstrap";
import "./Settings.scss";
import api from "../../api/api";

const Settings = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log(errors, "errors");

  const onSubmit = (data) => {
    console.log(data);
    // api
    api
      .post("/change-password", data)
      .then((res) => {
        toast.success("sucsses");
        console.log(res, "res");
      })
      .catch((err) => {
        err.response.data.error.confirm_password &&
          err.response.data.error.confirm_password.map((el) => toast.error(el));
        err.response.data.error["current-password"] &&
          err.response.data.error["current-password"].map((el) =>
            toast.error(el)
          );
        err.response.data.error.new_password &&
          err.response.data.error.new_password.map((el) => toast.error(el));
        toast.error(err);
        console.log(err, "err");
      });
  };

  return (
    <div className="box settings">
      <form
        id="add-Employees"
        className="m-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <MyInput
          label={t("currentPassword")}
          type="text"
          registered={{
            ...register("current-password"),
          }}
          error={errors?.["current-password"]?.message && t("currentPassword")}
        />

        <MyInput
          label={t("newPassword")}
          type="text"
          registered={{
            ...register("new_password"),
          }}
          error={errors.new_password?.message && t("newPassword")}
        />

        <MyInput
          label={t("confirmPassword")}
          type="text"
          registered={{
            ...register("confirm_password"),
          }}
          error={errors.confirm_password?.message && t("confirmPassword")}
        />

        <Button type="submit">{t("save")}</Button>
      </form>
    </div>
  );
};

export default Settings;
