import MyCard from "../../components/MyCard/MyCard";
import "./Companies.scss";
import { Container, Row, Col, Button, Pagination } from "react-bootstrap";
import MyModal from "../../components/MyModal/MyModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import MyInput from "../../components/Input/MyInput";
import { ReactComponent as CompaniesIcon } from "../../assets/icons/sidebar/companies.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as AddICon } from "../../assets/icons/add.svg";
import { getCompanies } from "../../api/apiFunctions";
import { useQuery } from "react-query";
import Loading from "../../components/Loading/Loading";
import api from "../../api/api";
import EditForm from "./EditForm";
import * as yup from "yup";
import MyCardCompany from "../../components/MyCardCompany/MyCardCompany";
import { confirmAlert } from "react-confirm-alert";

const Companies = () => {
    const [show, setShow] = useState(false);
    const [showedit, setShowedit] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [modalData, setDataModel] = useState();

    // modal function
    const handleclose = () => setShow(false);
    const handleshow = () => setShow(true);
    const handlecloseedit = () => setShowedit(false);
    const handleshowedit = () => setShowedit(true);

    const { isLoading, isError, data, error, refetch } = useQuery(
        ["companies", pageNumber],
        getCompanies
    );
    const { t } = useTranslation();

    const handleDataModal = (modalData) => {
        setDataModel(modalData);
        console.log(modalData);
        handleshowedit();
    };

    const deleteProject = (id) => {
        confirmAlert({
            title: t("alert"),
            buttons: [
                {
                    label: t("yes"),
                    onClick: async () =>
                        await api
                            .delete(`/companies/${id}`)
                            .then((res) => {
                                toast.success(t("deletesucc"));
                                refetch();
                                console.log(res, "res");
                            })
                            .catch((err) => {
                                toast.error(err.message);
                                console.log(err, "err");
                            }),
                },
                {
                    label: t("no"),
                    onClick: () => toast.info(t("deletefail")),
                },
            ],
        });
    };

    // import translation
    // yup schema
    const schema = yup
        .object()
        .shape({
            name: yup.string().required(),
            // description: yup.string().required(),
            // image: yup
            //     .mixed()
            //     .required("you need to upload file")
            //     .test("required", "You need to provide a file", (value) => {
            //         return value && value.length;
            //     }),
        })
        .required();

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    //add Companies
    const onSubmit = async ({ name, description, image }) => {
        const myImage = image[0];

        var data = new FormData();
        data.append("name", name);
        data.append("description", description);
        data.append("image", myImage);

        await api
            .post("/companies", data)
            .then((res) => {
                toast.success(t("done"));
                refetch();
                console.log(res, "res suc company");
            })
            .catch((err) => {
                for (let i in err?.response?.data.error) {
                    toast.error(`${err?.response.data.error[i]}`);
                }

                console.log(err, "err company");
            });

        reset();
        handleclose();
    };

    const handledsave = () => {
        toast.success(t("done"));
        handleclose();
    };
    const handledsaveedit = () => {
        toast.success(t("done"));
        handlecloseedit();
    };

    if (isLoading) {
        return <Loading />;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    return (
        <Container fluid className=" projects ">
            <div className="sectionTitle p-3 d-flex align-items justify-content-between">
                <span className="title">
                    <CompaniesIcon /> {t("Companies")}
                </span>
                <Button variant="success" onClick={handleshow}>
                    <AddICon />
                    {t("addCompanies")}
                </Button>
            </div>
            <Row>
                {data.data.map(({ id, name, description, image }, index) => (
                    <Col key={index} sm={12} md={6} lg={4}>
                        <MyCardCompany
                            handleshow={handleshowedit}
                            handleDataModal={handleDataModal}
                            deleteProject={deleteProject}
                            id={id}
                            name={name}
                            description={description}
                            image={`http://tahhsen.alqimma.info/${image}`}
                            datamodel={setDataModel}
                        />
                    </Col>
                ))}
                <div className="d-flex align-center justify-content-center mt-5">
                    <Pagination>
                        {data?.links
                            ?.slice(1, data.links.length - 1)
                            .map((el) => (
                                <Pagination.Item
                                    key={el.label}
                                    active={el.active}
                                    onClick={() => setPageNumber(el.label)}
                                >
                                    {el.label}
                                </Pagination.Item>
                            ))}
                    </Pagination>
                </div>
            </Row>
            {/* modal add projects */}
            <MyModal
                title={t("addProjects")}
                handleshow={handleshow}
                handleclose={handleclose}
                onhide={handleclose}
                show={show}
                handledsave={handledsave}
                formRef="add-companies"
                size="md"
            >
                <form
                    id="add-companies"
                    className="m-4 row"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <MyInput
                        label={t("table.name")}
                        type="text"
                        registered={{
                            ...register("name"),
                        }}
                        error={errors.name?.message && t("errors.reqName")}
                    />
                    <MyInput
                        label={t("table.description")}
                        type="text"
                        registered={{
                            ...register("description"),
                        }}
                        error={errors.description?.message}
                    />

                    <MyInput
                        label={t("table.image")}
                        type="file"
                        registered={{
                            ...register("image"),
                        }}
                        error={errors.image?.message}
                    />
                </form>
            </MyModal>
            {/* modal add projects */}
            {/* modal edit projects */}
            <MyModal
                title={t("editProjects")}
                handleshow={handleshowedit}
                handleclose={handlecloseedit}
                onhide={handlecloseedit}
                show={showedit}
                handledsave={handledsaveedit}
                formRef="edit-companies"
                size="md"
            >
                <EditForm
                    modalData={modalData}
                    handleclose={handlecloseedit}
                    refetch={refetch}
                />
            </MyModal>
            {/* modal edit projects */}
        </Container>
    );
};

export default Companies;
