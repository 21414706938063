import { useTranslation } from "react-i18next";
import MyInput from "../../components/Input/MyInput";
import { useForm } from "react-hook-form";
import { schema } from "./YupSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import api from "../../api/api";
import { Form } from "react-bootstrap";

const EditForm = ({ modalData, handleclose, refetch, companies }) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    // edit
    const onSubmitEdit = async (data) => {
        const { id } = modalData;

        await api
            .post(`/projects/${id}`, data)
            .then((res) => {
                toast.success(t("done"));
                refetch();
            })
            .catch((err) => {
                if (typeof err?.response?.data?.error !== "string") {
                    for (let i in err?.response?.data.error) {
                        toast.error(`${err?.response.data.error[i]}`);
                    }
                } else {
                    toast.error(err.response.data.error);
                }
                console.log(err);
            });

        handleclose();
    };

    return (
        <form
            id="edit-projects"
            class="m-4"
            onSubmit={handleSubmit(onSubmitEdit)}
        >
            <MyInput
                label={t("table.name")}
                type="text"
                defaultValue={modalData?.name}
                registered={{
                    ...register("name"),
                }}
                error={errors.name?.message}
            />
            <MyInput
                label={t("table.description")}
                type="text"
                defaultValue={modalData?.area}
                registered={{
                    ...register("description"),
                }}
                error={errors.description?.message}
            />
            <Form.Group className="mb-4 selectBox" controlId="company_id">
                <Form.Label>{t("companyName")}</Form.Label>
                <Form.Select
                    name="company_id"
                    id="company_id"
                    {...register("company_id")}
                    defaultValue={modalData?.company_id}
                >
                    {companies?.data?.data?.data.map((el, idx) => (
                        <option key={idx} value={el.id}>
                            {el.name}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>
        </form>
    );
};

export default EditForm;
