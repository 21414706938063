import * as yup from "yup";

// yup schema
export const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
   
  })
  .required();
