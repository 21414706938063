import { useState } from "react";
import { Button, Stack } from "react-bootstrap";
import MyTable from "../../components/MyTable/MyTable";
import MyModal from "../../components/MyModal/MyModal";
import { ReactComponent as EditIcon } from "../../assets/icons/table/edit.svg";
import { ReactComponent as AddICon } from "../../assets/icons/add.svg";
import { ReactComponent as UsersIcon } from "../../assets/icons/users.svg";
import { ReactComponent as DisabledIcon } from "../../assets/icons/table/disabled.svg";
import { ReactComponent as ActiveIcon } from "../../assets/icons/table/active.svg";
import { useTranslation } from "react-i18next";
import EmployeesForm from "./EmployeesForm";
import "./Employees.scss";
import { useQuery } from "react-query";
import { getUsers } from "../../api/apiFunctions";
import AddEmployeesForm from "./AddEmployeesForm";

const Employees = () => {
  // state
  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [pageNumber, setPageNumber] = useState("1");
  const [modalData, setmodalData] = useState();
  const [perPage, setPerPage] = useState(10);
  const { t } = useTranslation();

  //api
  const usersData = useQuery(["users", pageNumber, perPage], getUsers);

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  // modal function
  const handleclose = () => setShow(false);
  const handleshow = () => setShow(true);

  const handlecloseAdd = () => setShowAdd(false);
  const handleshowAdd = () => setShowAdd(true);

  // edit data
  const handleSaveData = (row) => {
    setmodalData(row);
    handleshow();
  };

  // table columns
  const columns = [
    {
      name: t("table.id"),
      selector: (row) => row.id,
      cellExport: (row) => row.id,
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      name: t("table.name"),
      selector: (row) => row.name,
      cellExport: (row) => row.name,
      sortable: true,
    },
    {
      name: t("table.email"),
      selector: (row) => row.email,
      cellExport: (row) => row.email,
      sortable: true,
    },
    {
      name: t("table.disabled"),
      selector: (row) => row.disabled,
      cellExport: (row) => row.disabled,
      sortable: true,
      cell: (row) =>
        row.disabled === 1 ? (
          <DisabledIcon style={{ color: "red" }} />
        ) : (
          <ActiveIcon style={{ color: "green" }} />
        ),
    },
    {
      name: t("table.Expire Date"),
      selector: (row) => row.expiry_date,
      cellExport: (row) => row.expiry_date,
      sortable: true,
    },

    {
      name: t("table.permissions"),
      selector: (row) =>
        row.admin === 1
          ? "Admin"
          : row.manager === 1
          ? "Manager"
          : row.read_only === 1
          ? "Read Only"
          : row.supervisor === 1
          ? "Supervisor"
          : "",
      cellExport: (row) =>
        row.admin === 1
          ? "Admin"
          : row.manager === 1
          ? "Manager"
          : row.read_only === 1
          ? "Read Only"
          : row.supervisor === 1
          ? "Supervisor"
          : "",
      sortable: true,
      cell: (row) =>
        row.admin === 1
          ? "Admin"
          : row.manager === 1
          ? "Manager"
          : row.read_only === 1
          ? "Read Only"
          : row.supervisor === 1
          ? "Supervisor"
          : "",
    },

    {
      name: t("table.actions"),
      minWidth: "230px",
      export: false,
      print: false,
      sortable: false,
      cell: (row) => (
        <Stack gap={2} direction="horizontal" className="my-3">
          <Button variant="primary" onClick={() => handleSaveData(row)}>
            <EditIcon width="22px" height="22px" className="mie" />
            {t("edit")}
          </Button>
        </Stack>
      ),
    },
  ];

  const data = usersData?.data?.data;
  const tableData = {
    columns,
    data,
  };

  // if (usersData?.isLoading) {
  //     return <h1>Loading...</h1>;
  // }

  if (usersData?.isError) {
    return <span>Error: {usersData?.error?.message}</span>;
  }

  return (
    <>
      <div className="sectionTitle p-3 d-flex align-items justify-content-between">
        <span className="title">
          <UsersIcon /> {t("emp")}
        </span>
        <Button variant="success" onClick={handleshowAdd}>
          <AddICon />
          {t("addEmp")}
        </Button>
      </div>
      <div className="employees box">
        <MyTable
          pending={usersData?.isLoading}
          fileName="tahsen employees"
          tableData={tableData}
          totalRows={usersData?.data?.total}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
        />

        <MyModal
          title={t("update")}
          handleshow={handleshow}
          handleclose={handleclose}
          onhide={handleclose}
          show={show}
          formRef="update-Employees"
          size="lg"

        >
          <EmployeesForm
            modalData={modalData}
            handleclose={handleclose}
            refetch={usersData.refetch}
          />
        </MyModal>

        <MyModal
          title={t("addEmp")}
          handleshow={handleshowAdd}
          handleclose={handlecloseAdd}
          onhide={handlecloseAdd}
          show={showAdd}
          formRef="add-Employees"
          size="lg"

        >
          <AddEmployeesForm
            handleclose={handlecloseAdd}
            refetch={usersData.refetch}
          />
        </MyModal>
      </div>
    </>
  );
};

export default Employees;
