import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as SaveICon } from "../../assets/icons/table/save.svg";
import { ReactComponent as CloseICon } from "../../assets/icons/table/close.svg";

const MyModal = ({ title, show, onhide, handleclose, children, formRef ,size}) => {
    const { t } = useTranslation();
    return (
        <Modal show={show} onHide={onhide} size={size}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleclose}>
                    <CloseICon width="22px" height="22px" className="mie" />
                    {t("close")}
                </Button>
                <Button variant="primary" form={formRef} type="submit">
                    <SaveICon width="22px" height="22px" className="mie" />
                    {t("save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MyModal;
