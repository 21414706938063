import { useTranslation } from "react-i18next";
import MyInput from "../../components/Input/MyInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import api from "../../api/api";
import * as yup from "yup";

const EditForm = ({ modalData, handleclose, refetch }) => {
    const { t } = useTranslation();
    const schema = yup
        .object()
        .shape({
            name: yup.string().required(),
            // description: yup.string().required(),
            // image: yup
            //     .mixed()
            //     .required("you need to upload file")
            //     .test("required", "You need to provide a file", (value) => {
            //         return value && value.length;
            //     }),
        })
        .required();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    // edit
    const onSubmitEdit = async (myData) => {
        const { id } = modalData;

        const myImage = myData.image[0];

        let data = new FormData();

        let myForm;

        if (myData?.image.length > 0) {
            myForm = { ...myData, image: myImage };
        } else {
            myForm = { ...myData };
            delete myForm.image;
        }

        Object.keys(myForm).forEach((key) => {
            data.append(key, myForm[key]);
            console.log(key, myForm[key]);
        });

        await api
            .post(`/companies/${id}`, data)
            .then((res) => {
                toast.success(t("done"));
                refetch();
                console.log(res, "res");
            })
            .catch((err) => {
                if (typeof err?.response?.data?.error !== "string") {
                    for (let i in err?.response?.data.error) {
                        toast.error(`${err?.response.data.error[i]}`);
                    }
                } else {
                    toast.error(err.response.data.error);
                }
                console.log(err, "err");
            });

        handleclose();
    };

    return (
        <form
            id="edit-companies"
            className="m-4"
            onSubmit={handleSubmit(onSubmitEdit)}
        >
            <MyInput
                label={t("table.name")}
                type="text"
                defaultValue={modalData?.name}
                registered={{
                    ...register("name"),
                }}
                error={errors.name?.message}
            />
            <MyInput
                label={t("table.description")}
                type="text"
                defaultValue={modalData?.description}
                registered={{
                    ...register("description"),
                }}
                error={errors.description?.message}
            />
            <MyInput
                label={t("table.image")}
                type="file"
                registered={{
                    ...register("image"),
                }}
                error={errors.image?.message}
            />
        </form>
    );
};

export default EditForm;
