import { useTranslation } from "react-i18next";
import MyInput from "../../components/Input/MyInput";
import { useForm } from "react-hook-form";
import { schema } from "./YupSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { FloatingLabel, Form } from "react-bootstrap";
import api from "../../api/api";
import { useQuery } from "react-query";
import Loading from "../../components/Loading/Loading";

const ReportsForm = ({
  refetch,
  dataModal,
  categories,
  projects,
  handleclose,
}) => {
  console.log(dataModal, "dataModal");
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // edit user
  const onSubmit = async (data) => {
    console.log(data, "dadaddad");
    // api
    await api
      .post(`/reports/${dataModal.id}`, data)
      .then((res) => {
        toast.success(t("done"));
        refetch();
        console.log(res, "res");
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        console.log(err, "err");
      });

    handleclose();
  };

  if (projects?.isLoading && categories?.isLoading) {
    return <Loading />;
  }

  return (
    <form id="update-Reports" className="m-4" onSubmit={handleSubmit(onSubmit)}>
      <MyInput
        label={t("table.description")}
        type="text"
        defaultValue={dataModal?.description}
        registered={{
          ...register("description"),
        }}
        error={errors.description?.message}
      />

      <Form.Group className="mb-4 selectBox" controlId="project_id">
        <Form.Label>{t("table.project")}</Form.Label>
        <Form.Select
          id="project_id"
          {...register("project_id")}
          defaultValue={dataModal?.project_id}
        >
          {projects?.data?.data?.data.map((el, idx) => (
            <option key={idx} value={el.id}>
              {el.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-4 selectBox" controlId="category_id">
        <Form.Label>{t("table.category")}</Form.Label>
        <Form.Select
          id="category_id"
          {...register("category_id")}
          defaultValue={dataModal?.category_id}
        >
          {categories?.data?.data?.data.map((el, idx) => (
            <option key={idx} value={el.id}>
              {el.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-4 selectBox" controlId="status">
        <Form.Label>{t("table.status")}</Form.Label>
        <Form.Select
          id="status"
          {...register("status")}
          defaultValue={dataModal?.status}
        >
          <option value="Pending">Pending</option>
          <option value="Progress">Progress</option>
          <option value="Completed">Completed</option>
        </Form.Select>
      </Form.Group>
    </form>
  );
};

export default ReportsForm;
