import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Pagination } from "swiper";
import { ReactComponent as SnIcon } from "../../assets/icons/singleReports/sn.svg";
import { ReactComponent as MapIcon } from "../../assets/icons/singleReports/map.svg";
import { ReactComponent as DateIcon } from "../../assets/icons/singleReports/date.svg";
import { ReactComponent as ReportsIcon } from "../../assets/icons/singleReports/report.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/email.svg";
import { ReactComponent as CommentsIcon } from "../../assets/icons/comments.svg";
import { ReactComponent as ReportsInfoIcon } from "../../assets/icons/sidebar/reports.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/bundle";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./SingleReports.scss";
import { useParams } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import api from "../../api/api";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const SingleReports = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState();

  const { id } = useParams();
  const { t } = useTranslation();

  const getSingleRebort = async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_, id] = queryKey;

    const data = await api.get(`/reports/${id}`);

    return data?.data?.data;
  };

  const { refetch, data } = useQuery(["singleReports", id], getSingleRebort);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ comment, attached }) => {
    const data = new FormData();
    data.append("comment", comment);
    data.append("report_id", id);

    for (let i = 0; i < attached.length; i++) {
      const element = attached[i];
      data.append("attached[]", element);
    }

    await api
      .post("/comments", data)
      .then((res) => {
        refetch();
        toast.success(t("done"));
      })
      .catch((err) => {
        console.log(err.response.data.error, "err.response.data.error");
        for (let i in err.response.data.error) {
          toast.error("input: " + i + ", error: " + err.response.data.error[i]);
        }
      });

    reset();
  };

  const handleDeleteComment = async (id) => {
    await api
      .delete(`/comments/${id}`)
      .then((res) => {
        refetch();
        toast.success("Done");
        console.log(res, "res");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <div className="box infoReports">
      <div className="infoReportsHeading">
        <ReportsInfoIcon />
        <h2>{t("infoReports")}</h2>
      </div>
      <div className="infoReportsBody">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={10}
          navigation={true}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2"
        >
          {data?.attached &&
            JSON.parse(data?.attached)?.map((el, idx) => {
              const extension = el.split(".")[1];

              return (
                <SwiperSlide key={idx}>
                  {extension === "mp4" ? (
                    <video
                      muted
                      width="600"
                      height="100%"
                      controls
                      src={`http://tahhsen.alqimma.info/${el}`}
                      style={{
                        display: "block",
                        margin: "0 auto",
                      }}
                    ></video>
                  ) : (
                    <img
                      src={`http://tahhsen.alqimma.info/${el}`}
                      width="80%"
                      height="80%"
                      className="topImages"
                      alt="images silder"
                    />
                  )}
                </SwiperSlide>
              );
            })}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {data?.attached &&
            JSON.parse(data?.attached)?.map((el, idx) => {
              const extension = el.split(".")[1];
              return (
                <SwiperSlide key={idx}>
                  {extension === "mp4" ? (
                    <div className="videoBK">VIDEO</div>
                  ) : (
                    <img
                      src={`http://tahhsen.alqimma.info/${el}`}
                      width="80%"
                      height="80%"
                      className="topImages"
                      alt="images silder"
                    />
                  )}
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
      <div className="infoReportsDetails my-5">
        <Row>
          <Col sm={12} md={6} lg={4}>
            <div className="details">
              <SnIcon />
              <h4>{data?.id}</h4>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <div className="details">
              <DateIcon />
              <h4>{`${new Date(data?.created_at).getFullYear()}-${new Date(
                data?.created_at
              ).getMonth()}-${new Date(data?.created_at).getDate()}`}</h4>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <div className="details">
              <ReportsIcon />
              <h4>{data?.category?.name}</h4>
            </div>
          </Col>
          <Col sm={12} md={12} lg={12}>
            <div className="details mt-3">
              <MapIcon />
              <h4 className="mb-4">
                {data?.address == null ? "Not found" : data?.address}
              </h4>
              <iframe
                width="100%"
                height="400px"
                src={`https://maps.google.com/maps?q=${data?.latitude},${data?.longitude}&hl=ar;&output=embed`}
              ></iframe>
            </div>
          </Col>
        </Row>
      </div>
      <div className="comments">
        <h3>
          <CommentsIcon />
          {t("comments")}
        </h3>
        {data?.comments?.map((el) => (
          <div key={el.id} className="commentDetails">
            <div className="d-flex justify-content-between align-items-center">
              <div className="commentInfo">
                <img
                  src={`http://tahhsen.alqimma.info/${el?.user.image}`}
                  alt="avatar"
                  width="60px"
                  height="60px"
                />
                <div className="info">
                  <h4 className="name">{el.user.name}</h4>
                  <span className="email">
                    <EmailIcon width="20px" height="20px" />
                    {el.user.email}
                  </span>
                  <span className="date">
                    <DateIcon width="15px" height="20px" />
                    {el?.created_at}
                  </span>
                </div>
              </div>

              <Button
                variant="danger"
                onClick={() => handleDeleteComment(el.id)}
                className="deleteComment"
              >
                {t("delete")}
              </Button>
            </div>

            <p className="comment">{el?.comment}</p>
            <Swiper
              pagination={{
                type: "progressbar",
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              {el?.attached &&
                JSON.parse(el?.attached)?.map((el, idx) => {
                  const extension = el.split(".")[1];
                  return (
                    <SwiperSlide key={idx}>
                      {extension === "mp4" ? (
                        <video
                          muted
                          width="600px"
                          height="400px"
                          controls
                          src={`http://tahhsen.alqimma.info/${el}`}
                          style={{
                            display: "block",
                            margin: "0 auto",
                          }}
                        ></video>
                      ) : (
                        <img
                          src={`http://tahhsen.alqimma.info/${el}`}
                          width="600px"
                          height="400px"
                          className="topImages"
                          alt="images silder"
                        />
                      )}
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        ))}
      </div>
      <div className="addComment">
        <h3>{t("addComment")}</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>{t("writeYourComment")}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              {...register("comment", { required: true })}
            />
            {errors.comment && (
              <span className="text-danger form-text">
                This field is required
              </span>
            )}
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>{t("addFile")}</Form.Label>
            <Form.Control
              type="file"
              multiple
              accept="image/*, video/mp4"
              {...register("attached", { required: true })}
            />
            {errors.attached && (
              <span className="text-danger form-text">
                This field is required
              </span>
            )}
          </Form.Group>
          <Button variant="primary" type="submit">
            <AddIcon />
            {t("addComment")}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SingleReports;
