import MyCard from "../../components/MyCard/MyCard";
import "./Projects.scss";
import { Container, Row, Col, Button, Pagination, Form } from "react-bootstrap";
import MyModal from "../../components/MyModal/MyModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import MyInput from "../../components/Input/MyInput";
import { ReactComponent as ProjectsIcon } from "../../assets/icons/sidebar/projects.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./YupSchema";
import { ReactComponent as AddICon } from "../../assets/icons/add.svg";
import { getProjects } from "../../api/apiFunctions";
import { useQuery } from "react-query";
import Loading from "../../components/Loading/Loading";
import api from "../../api/api";
import EditForm from "./EditForm";
import { confirmAlert } from "react-confirm-alert";

const Projects = () => {
    const [show, setShow] = useState(false);
    const [showedit, setShowedit] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [modalData, setDataModel] = useState();

    // modal function
    const handleclose = () => setShow(false);
    const handleshow = () => setShow(true);
    const handlecloseedit = () => setShowedit(false);
    const handleshowedit = () => setShowedit(true);

    const { isLoading, isError, data, error, refetch } = useQuery(
        ["projects", pageNumber],
        getProjects,
        { keepPreviousData: true }
    );

    const companies = useQuery(
        "companies",
        async () => await api.get("/companies?length=no")
    );

    const handleDataModal = (modalData) => {
        setDataModel(modalData);
        handleshowedit();
    };

    const deleteProject = (id) => {
        confirmAlert({
            title: t("alert"),

            buttons: [
                {
                    label: t("yes"),
                    onClick: async () =>
                        await api
                            .delete(`/projects/${id}`)
                            .then((res) => {
                                toast.success(t("deletesucc"));
                                refetch();
                            })
                            .catch((err) => {
                                toast.error(err.message);
                            }),
                },
                {
                    label: t("no"),
                    onClick: () => toast.info(t("deletefail")),
                },
            ],
        });
    };

    // import translation
    const { t } = useTranslation();
    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    //add projects
    const onSubmit = async (data) => {
        await api
            .post("/projects", data)
            .then((res) => {
                toast.success(t("done"));
                refetch();
            })
            .catch((err) => {
                if (typeof err?.response?.data?.error !== "string") {
                    for (let i in err?.response?.data.error) {
                        toast.error(`${err?.response.data.error[i]}`);
                    }
                } else {
                    toast.error(err.response.data.error);
                }
            });

        reset();
        handleclose();
    };

    const handledsave = () => {
        toast.success(t("done"));
        handleclose();
    };
    const handledsaveedit = () => {
        toast.success(t("done"));
        handlecloseedit();
    };

    if (isLoading) {
        return <Loading />;
    }

    if (isError) {
        return <span>Error: {error.message}</span>;
    }

    return (
        <Container fluid className="projects">
            <div className="sectionTitle p-3 d-flex align-items justify-content-between">
                <span className="title">
                    <ProjectsIcon /> {t("projects")}
                </span>
                <Button variant="success" onClick={handleshow}>
                    <AddICon />
                    {t("addProjects")}
                </Button>
            </div>
            <Row>
                {data?.data?.map(
                    ({ id, name, description, company_id }, idx) => (
                        <Col key={idx} sm={12} md={6} lg={4}>
                            <MyCard
                                handleshow={handleshowedit}
                                handleDataModal={handleDataModal}
                                deleteProject={deleteProject}
                                id={id}
                                name={name}
                                area={description}
                                company_id={company_id}
                                number={companies?.data?.data?.data.map((el) =>
                                    el.id === company_id ? el.name : ""
                                )}
                                datamodel={setDataModel}
                            />
                        </Col>
                    )
                )}
                <div className="d-flex align-center justify-content-center mt-5">
                    <Pagination>
                        {data?.links
                            ?.slice(1, data.links.length - 1)
                            .map((el, idx) => (
                                <Pagination.Item
                                    key={idx}
                                    active={el.active}
                                    onClick={() => setPageNumber(el.label)}
                                >
                                    {el.label}
                                </Pagination.Item>
                            ))}
                    </Pagination>
                </div>
            </Row>
            {/* modal add projects */}
            <MyModal
                title={t("addProjects")}
                handleshow={handleshow}
                handleclose={handleclose}
                onhide={handleclose}
                show={show}
                handledsave={handledsave}
                formRef="add-projects"
                size="md"
            >
                <form
                    id="add-projects"
                    class="m-4"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <MyInput
                        label={t("table.name")}
                        type="text"
                        registered={{
                            ...register("name"),
                        }}
                        error={errors.name?.message && t("errors.reqName")}
                    />
                    <MyInput
                        label={t("table.description")}
                        type="text"
                        registered={{
                            ...register("description"),
                        }}
                        error={
                            errors.description?.message &&
                            t("errors.description")
                        }
                    />
                    {/* <MyInput
            label="Company id"
            type="text"
            registered={{
              ...register("company_id"),
            }}
            error={errors.company_id?.message}
          /> */}

                    <Form.Group
                        className="mb-4 selectBox"
                        controlId="company_id"
                    >
                        <Form.Label>{t("companyName")}</Form.Label>
                        <Form.Select
                            name="company_id"
                            id="company_id"
                            {...register("company_id")}
                        >
                            {companies?.data?.data?.data.map((el, idx) => (
                                <option key={idx} value={el.id}>
                                    {el.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </form>
            </MyModal>
            {/* modal add projects */}
            {/* modal edit projects */}
            <MyModal
                title={t("editProjects")}
                handleshow={handleshowedit}
                handleclose={handlecloseedit}
                onhide={handlecloseedit}
                show={showedit}
                handledsave={handledsaveedit}
                formRef="edit-projects"
                size="md"
            >
                <EditForm
                    companies={companies}
                    modalData={modalData}
                    handleclose={handlecloseedit}
                    refetch={refetch}
                />
            </MyModal>
            {/* modal edit projects */}
        </Container>
    );
};

export default Projects;
