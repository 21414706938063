import { createContext, useState } from "react";

export const LangContext = createContext({});

export const LangProvider = ({ children }) => {
  const localLang = localStorage.getItem("lang");

  const [lang, setLang] = useState(localLang);

  const value = { lang, setLang };

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
};
