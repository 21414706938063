import { Route, Routes } from "react-router-dom";
import Reports from "../Reports/Reports";
import SingleReports from "../SingleReports/SingleReports";

const ReportsRoute = () => {
  return (
    <Routes>
      <Route index element={<Reports />} />
      <Route path=":id" element={<SingleReports />} />
    </Routes>
  );
};

export default ReportsRoute;
