import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

import "./HomeCharts.scss";

const HomeChart = ({ id, title, data, Icon }) => {
  const { t } = useTranslation();

  return (
    <div id={id} className="boxCharts">
      <h2>
        <Icon width="34px" height="34px" className="mx-2" />
        {t(`charts.${title}`)}
      </h2>
      <Chart
        options={data.options}
        series={data.series}
        type="area"
        width="100%"
        height="250px"
      />
    </div>
  );
};

export default HomeChart;
