import * as yup from "yup";

// yup schema
export const schema = yup
  .object({
    "current-password": yup.string().required(),
    new_password: yup.string().required(),
    confirm_password: yup.string().required(),
  })
  .required();
