import { useContext } from "react";

// use translation
import { useTranslation } from "react-i18next";

// bootstrap components
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

// import icons and images
import avatar from "../../assets/icons/appBar/avatar.jpg";
import USFlag from "../../assets/icons/appBar/united-states-of-america.png";
import egyptFlag from "../../assets/icons/appBar/egypt.png";
import { ReactComponent as ToggleIcons } from "../../assets/icons/appBar/toggleIcon.svg";
import { ReactComponent as CollapsIcon } from "../../assets/icons/appBar/collapsIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";

import { LangContext } from "../../contexts/langContext";
import { SidebarContxt } from "../../contexts/sidebarContext";
import DarkMode from "../../components/DarkandLight/DarkMode";

import "./Appbar.scss";
import { useQuery } from "react-query";

import { Link, useNavigate } from "react-router-dom";
import { getMe } from "../../api/apiFunctions";
import Loading from "../../components/Loading/Loading";
import { useEffect } from "react";

const Appbar = () => {
  const { toggled, setToggled, collapsed, setCollapsed } =
    useContext(SidebarContxt);
  const { lang, setLang } = useContext(LangContext);

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  // change language
  const changeLanguage = (lang) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    document.getElementsByTagName("html")[0].setAttribute("lang", lang);
    lang === "ar"
      ? document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
      : document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    setLang(lang);
  };

  // Api
  const { isLoading, isError, data, error, refetch } = useQuery("me", getMe);

  useEffect(() => {
    refetch();
  }, []);

  localStorage.setItem("userID", data?.id);
  return (
    <div className="appbar box">
      <Navbar expand="lg">
        <Nav className="me-auto">
          {/* <Nav.Link> */}
          <button
            className="mybtn-primary toggleSidebar"
            onClick={() => setToggled(!toggled)}
          >
            <ToggleIcons width="22px" height="22px" />
          </button>
          {/* </Nav.Link> */}

          {/* <Nav.Link> */}
          <button
            className="mybtn-primary collapsedSidebar"
            onClick={() => setCollapsed(!collapsed)}
          >
            <CollapsIcon className={collapsed ? "rotate" : ""} />
          </button>

          <DarkMode className="mybtn-primary" />

          {/* </Nav.Link> */}

          {/* <Nav.Link> */}
          {lang === "ar" ? (
            <button
              className="mybtn-primary"
              onClick={() => changeLanguage("en")}
            >
              {t("lang")}
              <img
                src={USFlag}
                className="icon"
                style={{ width: "15px", height: "15px" }}
                alt="lang en"
              />
            </button>
          ) : (
            <button
              className="mybtn-primary"
              onClick={() => changeLanguage("ar")}
            >
              {t("lang")}
              <img
                className="icon"
                src={egyptFlag}
                style={{ width: "15px", height: "15px" }}
                alt="lang ar"
              />
            </button>
          )}
          {/* </Nav.Link> */}
        </Nav>

        <Nav>
          {isLoading ? (
            "loding..."
          ) : (
            <NavDropdown
              title={
                <div className="profileInfo">
                  <div className="Info">
                    <p className="name">{data?.name}</p>
                    <span className="postion">
                      {data?.admin === 1
                        ? "Admin"
                        : data?.manager === 1
                        ? "Manager"
                        : data?.read_only === 1
                        ? "Read Only"
                        : data?.supervisor === 1
                        ? "Supervisor"
                        : console.log("no")}
                    </span>
                  </div>
                  <div className="ava">
                    <img
                      src={`http://tahhsen.alqimma.info/${data?.image}`}
                      alt="profileInfo"
                    />
                  </div>
                </div>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item as={Link} to="settings">
                <SettingsIcon width="30px" />
                {t("settings")}
              </NavDropdown.Item>

              {/* <NavDropdown.Item as={Link} to="changePhoto">
                <SettingsIcon width="30px" />
                {t("changePhoto")}
              </NavDropdown.Item> */}

              {/* <NavDropdown.Divider /> */}
              <NavDropdown.Item
                onClick={() => {
                  localStorage.clear();
                  navigate("/login");
                }}
              >
                <LogoutIcon width="30px" />
                {t("logout")}
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
      </Navbar>
    </div>
  );
};

export default Appbar;
