// import icons and images
import USFlag from "../../assets/icons/appBar/united-states-of-america.png";
import egyptFlag from "../../assets/icons/appBar/egypt.png";
import { useContext } from "react";
import { LangContext } from "../../contexts/langContext";
import { useTranslation } from "react-i18next";

const LangButton = () => {
  const { lang, setLang } = useContext(LangContext);

  const { t, i18n } = useTranslation();

  // change language
  const changeLanguage = (lang) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
    document.getElementsByTagName("html")[0].setAttribute("lang", lang);
    lang === "ar"
      ? document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
      : document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    setLang(lang);
  };

  return (
    <div className="langButton">
      {lang === "ar" ? (
        <button className="mybtn-primary" onClick={() => changeLanguage("en")}>
          {t("lang")}
          <img
            src={USFlag}
            className="icon"
            style={{ width: "15px", height: "15px" }}
            alt="lang en"
          />
        </button>
      ) : (
        <button className="mybtn-primary" onClick={() => changeLanguage("ar")}>
          {t("lang")}
          <img
            className="icon"
            src={egyptFlag}
            style={{ width: "15px", height: "15px" }}
            alt="lang ar"
          />
        </button>
      )}
    </div>
  );
};

export default LangButton;
