import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./Layout/Layout";
import Employees from "./pages/Employees/Employees";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import NotFound from "./pages/NotFound/NotFound";
import Projects from "./pages/Projects/Projects";
import ReportsRoute from "./pages/ReportsRoute/ReportsRoute";
import Settings from "./pages/Settings/Settings";
import Signup from "./pages/Signup/Signup";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Companies from "./pages/Companies/Companies";
import Category from "./pages/Category/Category";
import Permissions from "./pages/Permissions/Permissions";
import ChangePhoto from "./pages/ChangePhoto/ChangePhoto";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
        },
    },
});

function App() {
    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="employees" element={<Employees />} />
                        <Route path="companies" element={<Companies />} />
                        <Route path="reports/*" element={<ReportsRoute />} />
                        <Route path="projects" element={<Projects />} />
                        <Route path="settings" element={<Settings />} />
                        <Route path="changePhoto" element={<ChangePhoto />} />
                        <Route path="category" element={<Category />} />
                        <Route path="permissions" element={<Permissions />} />
                    </Route>
                    <Route path="login" element={<Login />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </div>
    );
}

export default App;
