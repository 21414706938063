import axios from "axios";

const api = axios.create({
  baseURL: "https://tahhsen.alqimma.info",
});

api.interceptors.request.use(
  (config) => {
    if (localStorage.token) {
      let token = localStorage.getItem("token");
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    const error = err?.response?.data?.error;
    return console.log(error, "errrororo");
  }
);

api.interceptors.response.use(undefined, (err) => {
  const error = err?.response?.data?.error;

  console.log(error, "errorr res api");

  if (
    err?.response?.data?.error === "Unauthenticated." ||
    err?.response?.data?.error === "Unauthorized."
  ) {
    console.log("error => Unauthenticated.");
    window.location.href = "/login";
    window.localStorage.clear();
  }

  return Promise.reject(err);
});

export default api;
