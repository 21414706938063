import { useTranslation } from "react-i18next";
import MyInput from "../../components/Input/MyInput";
import { useForm } from "react-hook-form";
import { schema } from "./YupSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import api from "../../api/api";
import { useState } from "react";

const AddCategoryForm = ({ handleclose, fetchUsers }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        await api
            .post("/categories", data)
            .then((res) => {
                toast.success(t("done"));
                fetchUsers();
                console.log(res, "res");
            })
            .catch((err) => {
                for (let i in err.response.data.error) {
                    toast.error(`${err.response.data.error[i]}`);
                }
                console.log(err, "err");
            });

        handleclose();
    };

    return (
        <form
            id="add-categories"
            className="m-4"
            onSubmit={handleSubmit(onSubmit)}
        >
            <MyInput
                label={t("table.name")}
                type="text"
                registered={{
                    ...register("name"),
                }}
                error={errors?.name?.message && t("errors.reqName")}
            />
        </form>
    );
};

export default AddCategoryForm;
