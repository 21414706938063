import { useTranslation } from "react-i18next";
import MyTable from "../../components/MyTable/MyTable";
import { Button, Col, Stack, Row } from "react-bootstrap";
import { useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as EditIcon } from "../../assets/icons/table/edit.svg";
import { ReactComponent as ReportsIcon } from "../../assets/icons/home/reports.svg";
import { ReactComponent as ViewsIcon } from "../../assets/icons/view.svg";
import MyModal from "../../components/MyModal/MyModal";
import ReportsForm from "./ReportsForm";
import DatePicker from "react-datepicker";
import { setDefaultLocale } from "react-datepicker";
import { useContext } from "react";
import { LangContext } from "../../contexts/langContext";
// import ar from "date-fns/locale/ar-EG";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import "./Reports.scss";
import { Link } from "react-router-dom";
import api from "../../api/api";
import { useQuery } from "react-query";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ReactComponent as DeleteIcon } from "../../assets/icons/table/delete.svg";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";

//registerLocale
// registerLocale("ar", ar);

const Reports = () => {
  // state
  const [valueState, setValueState] = useState();
  const [valueUser, setValueUser] = useState();
  const [valueCategory, setValueCategory] = useState();
  const [valueProject, setValueProject] = useState();
  const [show, setShow] = useState(false);
  const [dataModal, setDataModal] = useState();
  const [myFilterTableData, setMyFilterTableData] = useState([]);
  const [myFilterCategoryTableData, setMyFilterCategoryTableData] = useState(
    []
  );
  const [myFilterProjectsTableData, setMyFilterProjectsTableData] = useState(
    []
  );
  const [myFilterUserTableData, setMyFilterUserTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  console.log(startDate, "startDatestartDatestartDate");

  const getDateFrom = () => {
    function addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }

    const d = new Date(startDate);

    if (d === "Invalid Date") {
      return "";
    }

    const day = addZero(d.getDate());
    const monthEdit = d.getMonth() + 1;
    const month = addZero(monthEdit);
    const year = d.getFullYear();
    const hour = addZero(d.getHours());
    const minutes = addZero(d.getMinutes());
    const seconds = addZero(d.getSeconds());

    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
  };

  const getDateTo = () => {
    function addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }

    const d = new Date(endDate);

    if (d === "Invalid Date") {
      return "";
    }

    const day = addZero(d.getDate());
    const monthEdit = d.getMonth() + 1;
    const month = addZero(monthEdit);
    const year = d.getFullYear();
    const hour = addZero(d.getHours());
    const minutes = addZero(d.getMinutes());
    const seconds = addZero(d.getSeconds());

    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
  };

  const lang = useContext(LangContext);

  let FilterData = {
    status: myFilterTableData,
    category_ids: myFilterCategoryTableData,
    project_ids: myFilterProjectsTableData,
    user_ids: myFilterUserTableData,
    from: startDate ? getDateFrom() : "",
    to: endDate ? getDateTo() : "",
  };

  console.log(FilterData, "FilterData");

  const {
    isLoading,
    isError,
    data: dataReports,
    error,
    refetch,
  } = useQuery(
    ["reports", FilterData, pageNumber, perPage],
    async () =>
      await api
        .post(`/report_view?page=${pageNumber}&length=${perPage}`, FilterData)
        .catch((err) => {
          console.log(err, "errrrrrrrrrrrrro");
          toast.error(err?.response?.data?.error?.to[0]);
        })
  );

  const projects = useQuery(
    "reprtsProjects",
    async () => await api.get("/projects?length=no")
  );

  const categories = useQuery(
    "reprtsCategories",
    async () => await api.get("/categories?length=no")
  );

  const users = useQuery(
    "reprtsUsers",
    async () => await api.get("/users?length=no")
  );

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleChange = (change) => {
    let value = [];
    for (let i = 0, l = change.length; i < l; i++) {
      if (change[i].value) {
        value.push(change[i].value);
      }
    }

    setValueState(change);
    setMyFilterTableData(value);
    refetch();
  };

  const handleChangeCategory = (change) => {
    let value = [];
    for (let i = 0, l = change.length; i < l; i++) {
      if (change[i].value) {
        value.push(change[i].value);
      }
    }

    setValueCategory(change);
    setMyFilterCategoryTableData(value);
    refetch();
  };

  const handleChangeProjects = (change) => {
    let value = [];
    for (let i = 0, l = change.length; i < l; i++) {
      if (change[i].value) {
        value.push(change[i].value);
      }
    }

    setValueProject(change);
    setMyFilterProjectsTableData(value);
    refetch();
  };

  const handleChangeUsers = (change) => {
    let value = [];
    for (let i = 0, l = change.length; i < l; i++) {
      if (change[i].value) {
        value.push(change[i].value);
      }
    }

    setValueUser(change);
    setMyFilterUserTableData(value);
    refetch();
  };

  lang.lang === "ar" ? setDefaultLocale("ar") : setDefaultLocale("en");

  // import translation
  const { t } = useTranslation();

  // modal function
  const handleclose = () => setShow(false);
  const handleshow = () => setShow(true);

  // table functions
  const handledsave = () => {
    toast.success(t("done"));
    handleclose();
  };

  const handlesaveData = (row) => {
    setDataModal(row);
    handleshow();
  };

  const columns = [
    {
      name: t("table.id"),
      selector: (row) => row.id,
      cellExport: (row) => row.id,
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      name: t("table.description"),
      selector: (row) => row.description,
      cellExport: (row) => row.description,
      sortable: true,
      maxWidth: "200px",
    },
    {
      name: t("table.status"),
      selector: (row) => row.status,
      cellExport: (row) => row.status,
      conditionalCellStyles: [
        {
          when: (row) => row.status === "Completed",
          style: {
            color: "#00b833",
            fontWeight: "bold",
          },
        },
        {
          when: (row) => row.status === "Pending",
          style: {
            color: "#0b5ed7",
            fontWeight: "bold",
          },
        },
        {
          when: (row) => row.status === "Progress",
          style: {
            color: "#F38A00",
            fontWeight: "bold",
          },
        },
      ],
      cell: (row) => (
        <span>
          {row.status === "Completed" ? (
            <span className="mx-2 svg-icon svg-icon-muted svg-icon-2hx svg-icon-green">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          ) : row.status === "Progress" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              version="1.1"
              viewBox="0 0 700 700"
              className="mx-2"
            >
              <path
                fill="currentColor"
                d="m582.75 148.36-87.84 3.5547c-4.8398 0.19141-8.6016 4.2695-8.4102 9.1094 0.19531 4.8359 4.2734 8.6016 9.1094 8.4062l67.984-2.7695-89.227 107.39c5.0078 3.0625 9.5234 6.8398 13.383 11.227l87.535-105.29 2.6328 65.316-0.003906 0.003906c0.039063 2.3594 1.0273 4.6016 2.7422 6.2188 1.7148 1.6211 4.0117 2.4805 6.3672 2.3867s4.5742-1.1367 6.1523-2.8867c1.582-1.75 2.3867-4.0664 2.2383-6.418l-3.5547-87.84v-0.003906c-0.17969-4.5156-3.7734-8.1562-8.2891-8.3906-0.27344-0.019531-0.54688-0.023438-0.82031-0.015625zm-276.32 53.422c-21.992 0-40.043 18-40.043 39.988 0 21.992 18.051 39.973 40.043 39.973s39.988-17.98 39.988-39.973c0-21.992-18-39.988-39.988-39.988zm54.09 59.164c-2.0117 5.6055-4.8672 10.805-8.4258 15.465l38.418 27.496c2.0156-5.6055 4.8672-10.805 8.4258-15.465zm-104.57 8.2539-73.793 59.113c4.418 3.8477 8.2266 8.3438 11.312 13.348l73.145-58.617c-4.2031-4.0469-7.832-8.6992-10.664-13.844zm188.71 13.895c-21.992 0-40.043 18-40.043 39.988 0 21.992 18.051 39.988 40.043 39.988 21.992 0 39.988-18 39.988-39.988 0-21.992-18-39.988-39.988-39.988zm-300.08 48.52c-21.992 0-39.988 17.98-39.988 39.973 0 21.992 18 40.059 39.988 40.059 21.992 0 39.973-18.066 39.973-40.059 0-21.992-17.98-39.973-39.973-39.973z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              version="1.1"
              viewBox="0 0 700 700"
              className="mx-2"
            >
              <g>
                <path
                  fill="currentColor"
                  d="m560 367.5v105c0 4.6406-1.8438 9.0938-5.125 12.375s-7.7344 5.125-12.375 5.125h-6.3008c-3.5234-1.2891-6.5273-3.6992-8.5508-6.8594-2.0234-3.1641-2.9531-6.8984-2.6484-10.641v-105c0-6.2539 3.3359-12.031 8.75-15.156s12.086-3.125 17.5 0 8.75 8.9023 8.75 15.156z"
                />
                <path
                  fill="currentColor"
                  d="m560 542.5c0.054688 4.6406-1.8516 9.0898-5.25 12.25-1.4805 1.7734-3.4141 3.1055-5.6016 3.8516-2.1836 0.51953-4.4609 0.51953-6.6484 0-4.5938-0.066406-8.9766-1.9336-12.203-5.1992-3.2266-3.2695-5.043-7.6758-5.0508-12.266-0.007813-4.5938 1.7891-9.0078 5.0039-12.285 3.4648-2.7617 7.832-4.1328 12.25-3.8516 2.0703-0.49219 4.2305-0.49219 6.3008 0 2.1836 0.74609 4.1211 2.0781 5.5977 3.8516 3.918 3.4102 5.9961 8.4688 5.6016 13.648z"
                />
                <path
                  fill="currentColor"
                  d="m630 280c0.035156-62.527-20.863-123.27-59.359-172.54-38.5-49.273-92.383-84.242-153.06-99.332-60.68-15.094-124.67-9.4375-181.76 16.062-57.094 25.496-104.01 69.375-133.27 124.63-29.258 55.262-39.18 118.73-28.18 180.28 10.996 61.551 42.285 117.65 88.871 159.36 46.59 41.707 105.8 66.621 168.19 70.77 62.391 4.1484 124.38-12.707 176.07-47.879-11.172-9.8125-17.555-23.98-17.5-38.852v-105c0-18.758 10.008-36.09 26.25-45.465 16.242-9.3789 36.258-9.3789 52.5 0 16.242 9.375 26.25 26.707 26.25 45.465v47.602c23.043-41.297 35.094-87.812 35-135.1zm-245 17.5v1.75c0.13281 1.1641 0.13281 2.3359 0 3.5v2.8008l-2.1016 2.8008-2.4492 2.4492-105 70c-3.6914 2.875-8.2734 4.3594-12.949 4.1992-5.8516-0.054688-11.324-2.918-14.699-7.6992-2.5508-3.9141-3.4688-8.6719-2.5508-13.25 0.91406-4.582 3.5898-8.6211 7.4492-11.25l97.301-64.75v-165.55c0-6.2539 3.3359-12.031 8.75-15.156s12.086-3.125 17.5 0 8.75 8.9023 8.75 15.156z"
                />
              </g>
            </svg>
          )}
          {t(`charts.${row.status.toLowerCase()}`)}
        </span>
      ),
      minWidth: "200px",
    },
    {
      name: t("table.user"),
      selector: (row) => row.user.name,
      cellExport: (row) => row.user.name,
    },
    {
      name: t("table.category"),
      selector: (row) => row.category.name,
      cellExport: (row) => row.category.name,
    },
    {
      name: t("table.project"),
      selector: (row) => row.project.name,
      cellExport: (row) => row.project.name,
    },
    {
      name: t("table.created_at"),
      selector: (row) => row.created_at,
      cellExport: (row) => row.created_at,
      cell: (row) => {
        const year = new Date(row.created_at).getFullYear();
        const month = new Date(row.created_at).getMonth();
        const day = new Date(row.created_at).getDate();
        return <span>{`${year}-${month + 1}-${day}`}</span>;
      },
    },
    {
      name: t("table.actions"),
      export: false,
      print: false,
      minWidth: "240px",
      cell: (row) => {
        return (
          <Stack gap={2} direction="horizontal" className="my-3">
            <Button variant="primary" onClick={() => handlesaveData(row)}>
              <EditIcon width="22px" height="22px" className="mie" />
              {t("edit")}
            </Button>
            <Button to={`${row.id}`} as={Link} variant="secondary">
              <ViewsIcon width="22px" height="22px" className="mie" />
              {t("more")}
            </Button>
          </Stack>
        );
      },
    },
  ];

  const data = dataReports?.data?.data?.data;
  const tableData = {
    columns,
    data,
  };

  if (isError) {
    return <span>Error: {error?.message}</span>;
  }

  const filterStatusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Progress", label: "Progress" },
    { value: "Completed", label: "Completed" },
  ];
  const filterUsersOptions = users?.data?.data?.data.map((el) => ({
    value: el.id,
    label: el.name,
  }));

  const filterCategoryOptions = categories?.data?.data?.data.map((el) => ({
    value: el.id,
    label: el.name,
  }));

  const filterProjectsOptions = projects?.data?.data?.data.map((el) => ({
    value: el.id,
    label: el.name,
  }));

  const clearSelect = () => {
    setValueState(null);
    setValueUser(null);
    setValueCategory(null);
    setValueProject(null);

    setMyFilterTableData([]);
    setMyFilterCategoryTableData([]);
    setMyFilterProjectsTableData([]);
    setMyFilterUserTableData([]);

    setStartDate();
    setEndDate();
  };

  const animatedComponents = makeAnimated();

  const years = range(1998, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="reports">
      <div className="sectionTitle p-3 d-flex align-items justify-content-between">
        <span className="title">
          <ReportsIcon /> {t("reports")}
        </span>
      </div>

      <div className="box filterBox p-4">
        <h5>
          <div className="d-flex justify-content-between">
            <span>
              <FilterIcon width="20px" /> {t("table.filter")}
            </span>
            <Button variant="danger" onClick={clearSelect}>
              <DeleteIcon className="mx-1" />
              {t("resetFilter")}
            </Button>
          </div>
        </h5>
        <Row>
          <Col sm={12} md={6} lg={3} className="my-2">
            <Select
              isMulti
              autosize={true}
              components={animatedComponents}
              options={filterStatusOptions}
              value={valueState}
              onChange={handleChange}
              placeholder={t("selectStatus")}
            />
          </Col>

          <Col sm={12} md={6} lg={3} className="my-2">
            <Select
              isMulti
              components={animatedComponents}
              options={filterUsersOptions}
              value={valueUser}
              onChange={handleChangeUsers}
              isLoading={users.isLoading}
              isDisabled={users.isLoading}
              placeholder={t("selectUsers")}
            />
          </Col>

          <Col sm={12} md={6} lg={3} className="my-2">
            <Select
              isMulti
              components={animatedComponents}
              options={filterCategoryOptions}
              value={valueCategory}
              onChange={handleChangeCategory}
              isLoading={categories.isLoading}
              isDisabled={categories.isLoading}
              placeholder={t("selectCategory")}
            />
          </Col>

          <Col sm={12} md={6} lg={3} className="my-2">
            <Select
              isMulti
              components={animatedComponents}
              options={filterProjectsOptions}
              value={valueProject}
              onChange={handleChangeProjects}
              isLoading={projects.isLoading}
              isDisabled={projects.isLoading}
              placeholder={t("selectProject")}
            />
          </Col>
          <Col sm={12} md={6} lg={3} className="my-2">
            <DatePicker
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    className="btnSelectDate"
                  >
                    {"<"}
                  </button>

                  <select
                    id="dropdown-basic-button"
                    title="Dropdown button"
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                    className="dropdownSelect"
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                    className="dropdownSelect"
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    className="btnSelectDate"
                  >
                    {">"}
                  </button>
                </div>
              )}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy/MM/d"
              placeholderText={t("from")}
              withPortal
              required
              maxDate={new Date()}
              showTimeSelect
              shouldCloseOnSelect={true}
            />
          </Col>
          <Col sm={12} md={6} lg={3} className="my-2">
            <DatePicker
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    className="btnSelectDate"
                  >
                    {"<"}
                  </button>

                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                    className="dropdownSelect"
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                    className="dropdownSelect"
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    className="btnSelectDate"
                  >
                    {">"}
                  </button>
                </div>
              )}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="yyyy/MM/d"
              placeholderText={t("to")}
              withPortal
              required
              minDate={startDate}
              maxDate={new Date()}
              showTimeSelect
              shouldCloseOnSelect={true}
            />
          </Col>
        </Row>
      </div>

      <div className="reports box">
        <MyTable
          pending={isLoading}
          tableData={tableData}
          fileName="tahsen reports"
          totalRows={dataReports?.data?.data?.total}
          handlePageChange={handlePageChange}
          handlePerRowsChange={handlePerRowsChange}
        />
        <MyModal
          title={t("update")}
          handleshow={handleshow}
          handleclose={handleclose}
          onhide={handleclose}
          show={show}
          handledsave={handledsave}
          formRef="update-Reports"
          size="md"
        >
          <ReportsForm
            dataModal={dataModal}
            handleclose={handleclose}
            projects={projects}
            categories={categories}
            refetch={refetch}
          />
        </MyModal>
      </div>
    </div>
  );
};

export default Reports;
