import { createContext, useState } from "react";

export const SidebarContxt = createContext({
  toggled: false,
  setToggled: () => false,
  collapsed: false,
  setCollapsed: () => false,
});

export const SidebarProvider = ({ children }) => {
  const [toggled, setToggled] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const value = { toggled, setToggled, collapsed, setCollapsed };

  return (
    <SidebarContxt.Provider value={value}>{children}</SidebarContxt.Provider>
  );
};
