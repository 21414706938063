import { Outlet, Navigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar";
import Appbar from "../components/Appbar/Appbar";

import "./Layout.scss";

const Layout = () => {
  let token = localStorage.hasOwnProperty("token");

  let auth = { token: token };

  return auth.token ? (
    <div className="layout">
      <Sidebar />
      <div className="content">
        <Appbar />
        <Outlet />
        <Footer />
      </div>
    </div>
  ) : (
    <Navigate to="login" />
  );
};

export default Layout;
