import { useEffect, useState } from "react";
import "./DarkMode.scss";
import { ReactComponent as SunIcon } from "../../assets/icons/sun.svg";
import { ReactComponent as MoonIcon } from "../../assets/icons/moon.svg";

export default function DarkMode() {
  const [theme, settheme] = useState("light");

  const handleToggle = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    settheme(newTheme);
    storeUserSetPreference(newTheme);
    document.body.dataset.theme = theme;
  };

  const storeUserSetPreference = (pref) => {
    localStorage.setItem("theme", pref);
  };
  const getUserSetPreference = () => {
    return localStorage.getItem("theme");
  };

  useEffect(() => {
    const userSetPreference = getUserSetPreference();

    if (userSetPreference) {
      settheme(userSetPreference);
    }
    document.body.dataset.theme = theme;
  }, [theme]);
  return (
    <div className=" mybtn-primary toggle-theme-wrapper ">
      {theme === "light" ? (
        <span onClick={handleToggle}>
             <MoonIcon />
        </span>
      ) : (
        <span onClick={handleToggle}>
     
          <SunIcon />
        </span>
      )}
    </div>
  );
}
