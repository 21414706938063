import { useState } from "react";
import { useQuery } from "react-query";
import api from "../../api/api";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Col, Nav, Row, Tab } from "react-bootstrap";

import UsersTable from "./UsersTable";
import ProjectsTable from "./ProjectsTable";
import CompanyTable from "./CompanyTable";
import { ReactComponent as PermissionsIcon } from "../../assets/icons/sidebar/permissions.svg";

const Permissions = () => {
  const { t } = useTranslation();

  const modalUser = localStorage.getItem("userID");

  // my user in select box
  const [modalUserSelected, setModalUserSelected] = useState();

  // user select from select box
  const [modalUserIDSelected, setModalUserIDSelected] = useState();

  // --- users

  // table data users
  const [tableDataUsers, setTableDataUsers] = useState([]);
  // table data Companies
  const [tableDataCompanies, setTableDataCompanies] = useState([]);
  // table data Projects
  const [tableDataProjects, setTableDataProjects] = useState([]);

  //  --- user modal
  const { isLoading, isError, data, error, refetch } = useQuery(
    ["modalUser", modalUser],
    async () =>
      await api.get(`/permission/user/${modalUser}?model=User&length=no`),
    {
      cacheTime: 0,
      enabled: !!modalUser,
    }
  );

  // filter select box
  const filterUsersOptions = data?.data?.data?.map((el) => ({
    value: el.id,
    label: el.name,
  }));

  // api users modal

  // --- Get All users
  const getDataUSers = async () => await api.get("/users?length=no");

  const usersData = useQuery("usersModal", getDataUSers, {
    cacheTime: 0,
    onSuccess: (data) => {
      setTableDataUsers(data?.data?.data);
    },
  });

  // --- Get All users

  // --- Get All Companies
  const getDataCompanies = async () => await api.get("/companies?length=no");

  const companiesData = useQuery("CompaniesModal", getDataCompanies, {
    cacheTime: 0,
    onSuccess: (data) => {
      setTableDataCompanies(data?.data?.data);
    },
  });
  // --- Get All Companies

  // --- Get All Companies
  const getDataProjects = async () => await api.get("/projects?length=no");

  const ProjectsData = useQuery("ProjectsModal", getDataProjects, {
    cacheTime: 0,
    onSuccess: (data) => {
      setTableDataProjects(data?.data?.data);
    },
  });
  // --- Get All Companies

  // change select box
  const handleChange = (change) => {
    setModalUserSelected(change);
    setModalUserIDSelected(change.value);
  };

  const paginationComponentOptions = {
    rowsPerPageText: t("table.rowsPerPageText"),
    rangeSeparatorText: t("table.rangeSeparatorText"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("table.selectAllRowsItemText"),
  };

  return (
    <>
      <div className="sectionTitle p-3 d-flex align-items justify-content-between">
        <span className="title">
          <PermissionsIcon /> {t("table.permissions")}
        </span>
      </div>
      <div className="box">
        <Row sm={4}>
          <Col>
            <Select
            id="selectoOptions"
          
              autosize={true}
              options={filterUsersOptions}
              value={modalUserSelected}
              onChange={handleChange}
              placeholder={t("selectUsesr")}
              isLoading={isLoading}
              isDisabled={isLoading}
             
            />
          </Col>
        </Row>
        {!modalUserIDSelected ? (
          <div className="text-center fw-bold my-5 min-vh-50 d-flex align-items-center justify-content-center">
            {t("Pleaseselectusertosetpermissions")}
          </div>
        ) : (
          <Tab.Container id="left-tabs-example" defaultActiveKey="Users">
            <Row className="mt-5">
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="Users">{t("emp")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Companies">
                      {t("Companies")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Projects">{t("projects")}</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="Users">
                    <UsersTable
                      paginationComponentOptions={paginationComponentOptions}
                      modalUserIDSelected={modalUserIDSelected}
                      tableDataUsers={tableDataUsers}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Companies">
                    <CompanyTable
                      paginationComponentOptions={paginationComponentOptions}
                      modalUserIDSelected={modalUserIDSelected}
                      tableDataCompanies={tableDataCompanies}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Projects">
                    <ProjectsTable
                      paginationComponentOptions={paginationComponentOptions}
                      modalUserIDSelected={modalUserIDSelected}
                      tableDataUsers={tableDataProjects}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        )}
      </div>
    </>
  );
};

export default Permissions;
