import { useState } from "react";
import { Button, Stack } from "react-bootstrap";
import MyTable from "../../components/MyTable/MyTable";
import MyModal from "../../components/MyModal/MyModal";
import { toast } from "react-toastify";
import { ReactComponent as EditIcon } from "../../assets/icons/table/edit.svg";
import { ReactComponent as AddICon } from "../../assets/icons/add.svg";
import { ReactComponent as UsersIcon } from "../../assets/icons/users.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/table/delete.svg";

import { useTranslation } from "react-i18next";

import CategoryForm from "./CategoryForm";
import { useQuery } from "react-query";
import AddCategoryForm from "./AddCategoryForm";
import api from "../../api/api";
import { confirmAlert } from "react-confirm-alert";

const Category = () => {
  // state
  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [pageNumber, setPageNumber] = useState("1");
  const [perPage, setPerPage] = useState(10);
  const [modalData, setmodalData] = useState();
  const [totalRows, setTotalRows] = useState();

  const [newData, setNewData] = useState();

  const { t } = useTranslation();

  const { isLoading, isError, refetch } = useQuery(
    ["categoresPage", pageNumber, perPage],
    async () =>
      await api.get(`/categories?page=${pageNumber}&length=${perPage}`),
    {
      cacheTime: 0,
      onSuccess: (data) => {
        setNewData(data?.data?.data?.data);
        setTotalRows(data?.data?.data?.total);
      },
    }
  );

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  // modal function
  const handleclose = () => setShow(false);
  const handleshow = () => setShow(true);

  const handlecloseAdd = () => setShowAdd(false);
  const handleshowAdd = () => setShowAdd(true);

  // table functions
  const handledelete = (id) => {
    confirmAlert({
      title: t("alert"),
      buttons: [
        {
          label: t("yes"),
          onClick: async () =>
            await api
              .delete(`/categories/${id}`)
              .then((res) => {
                toast.success(t("deletesucc"));
                // usersData.refetch();
                refetch();
                console.log(res, "res");
              })
              .catch((err) => {
                toast.error(err.message);
                console.log(err, "err");
              }),
        },
        {
          label: t("no"),
          onClick: () => toast.info(t("deletefail")),
        },
      ],
    });
  };

  const handledsave = () => {
    toast.success(t("done"));
    handlecloseAdd();
  };

  // edit data
  const handleSaveData = (row) => {
    setmodalData(row);
    handleshow();
  };

  // table columns
  const columns = [
    {
      name: t("table.id"),
      selector: (row) => `${row.id}`,
      cellExport: (row) => row.id,
      minWidth: "100px",
      maxWidth: "100px",
    },
    {
      name: t("table.name"),
      selector: (row) => `${row.name}`,
      cellExport: (row) => row.name,
      sortable: true,
    },
    {
      name: t("table.created_at"),
      selector: (row) => `${row.created_at}`,
      cellExport: (row) => row.created_at,
      cell: (row) => {
        const year = new Date(row.created_at).getFullYear();
        const month = new Date(row.created_at).getMonth();
        const day = new Date(row.created_at).getDate();
        return <span>{`${year}-${month + 1}-${day}`}</span>;
      },
    },
    {
      name: t("table.actions"),
      selector: (row) => `${row.actions}`,
      minWidth: "260px",
      export: false,
      print: false,
      cell: (row) => (
        <Stack gap={2} direction="horizontal" className="my-3">
          <Button variant="primary" onClick={() => handleSaveData(row)}>
            <EditIcon width="22px" height="22px" className="mie" />
            {t("edit")}
          </Button>
          <Button variant="danger" onClick={() => handledelete(row.id)}>
            <DeleteIcon width="22px" height="22px" className="cardIcon mx-2" />

            {t("delete")}
          </Button>
        </Stack>
      ),
    },
  ];

  const data = newData;
  const tableData = {
    columns,
    data,
  };

  if (isError) {
    return <span>Error: {isError?.error?.message}</span>;
  }

  return (
    <>
      <div className="sectionTitle p-3 d-flex align-items justify-content-between">
        <span className="title">
          <UsersIcon /> {t("table.category")}
        </span>
        <Button variant="success" onClick={handleshowAdd}>
          <AddICon />
          {t("add")}
        </Button>
      </div>
      <div className="employees box">
        <MyTable
          pending={isLoading}
          fileName="tahsen category"
          tableData={tableData}
          totalRows={totalRows}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
        />

        <MyModal
          title={t("update")}
          handleshow={handleshow}
          handleclose={handleclose}
          onhide={handleclose}
          show={show}
          handledsave={handledsave}
          formRef="update-categories"
        >
          <CategoryForm
            modalData={modalData}
            handleclose={handleclose}
            fetchUsers={refetch}
          />
        </MyModal>

        <MyModal
          title={t("add")}
          handleshow={handleshowAdd}
          handleclose={handlecloseAdd}
          onhide={handlecloseAdd}
          show={showAdd}
          handledsave={handledsave}
          formRef="add-categories"
        >
          <AddCategoryForm handleclose={handlecloseAdd} fetchUsers={refetch} />
        </MyModal>
      </div>
    </>
  );
};

export default Category;
