import { useContext } from "react";
import { LangContext } from "../../contexts/langContext";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { ReactComponent as SortIcon } from "../../assets/icons/table/arr.svg";
import { useTranslation } from "react-i18next";
import "./MyTable.scss";
import Loading from "../Loading/Loading";

const MyTable = ({
  tableData,
  pending,
  fileName,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
}) => {
  const { lang } = useContext(LangContext);
  const { t } = useTranslation();
  const paginationComponentOptions = {
    rowsPerPageText: t("table.rowsPerPageText"),
    rangeSeparatorText: t("table.rangeSeparatorText"),
    selectAllRowsItem: true,
    selectAllRowsItemText: t("table.selectAllRowsItemText"),
  };

  createTheme("solarized", {
    text: {
      primary: "var(--color-text-primary)",
    },
    background: {
      default: "var(--color-bg-primary)",
      // shadow: "var(--box-shadow)",
    },
  });

  return (
    <DataTableExtensions
      filter={true}
      filterPlaceholder={t("table.filter")}
      fileName={fileName}
      exportHeaders={true}
      {...tableData}
    >
      <DataTable
        direction={lang === "ar" ? "rtl" : "ltr"}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        progressPending={pending}
        progressComponent={<Loading />}
        highlightOnHover
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortIcon={<SortIcon />}
        theme="solarized"
      />
    </DataTableExtensions>
  );
};

export default MyTable;
