import React from "react";
import { Button, Stack } from "react-bootstrap";
import { ReactComponent as EditIcon } from "../../assets/icons/table/edit.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as DeleteIcon } from "../../assets/icons/table/delete.svg";
import Card from "react-bootstrap/Card";
import "./MyCard.scss";

export default function MyCard({
  id,
  name,
  area,
  number,
  handleshow,
  handleDataModal,
  deleteProject,
  company_id,
}) {
  const { t } = useTranslation();
  return (
    <div className="mycard">
      <Card className="mb-4 box">
        <Card.Body>
          <Card.Title>
            <div className="text-center">{name}</div>
          </Card.Title>
          <div className="cardContent">
            <Card.Text>Description</Card.Text>
            <Card.Text>{area}</Card.Text>
          </div>
          <div className="cardContent">
            <Card.Text>Company Name</Card.Text>
            <Card.Text>{number}</Card.Text>
          </div>
          <div className="Icons">
            <Button
              variant="primary"
              onClick={() =>
                handleDataModal({ id, name, area, company_id, number })
              }
            >
              <EditIcon width="22px" height="22px" className="cardIcon" />
              {t("edit")}
            </Button>
            <Button variant="danger" onClick={() => deleteProject(id)}>
              <DeleteIcon width="22px" height="22px" className="cardIcon" />
              {t("delete")}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
