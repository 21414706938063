import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-data-table-component-extensions/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "swiper/css";
import "./index.css";

import "./i18n/config";
import { SidebarProvider } from "./contexts/sidebarContext";
import { LangProvider } from "./contexts/langContext";

import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SidebarProvider>
        <LangProvider>
          <App />
          <ToastContainer position="top-center" autoClose={8000} draggable />
        </LangProvider>
      </SidebarProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
