import { useTranslation } from "react-i18next";
import MyInput from "../../components/Input/MyInput";
import { useForm } from "react-hook-form";
import { schema } from "./YupSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Col, Form, Row } from "react-bootstrap";
import api from "../../api/api";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";

const AddEmployeesForm = ({ handleclose, refetch }) => {
    const { t } = useTranslation();

    // let [value, setValue] = useState(false);
    const [startDate, setStartDate] = useState(new Date());

    var year = startDate.toLocaleString("default", { year: "numeric" });
    var month = startDate.toLocaleString("default", { month: "2-digit" });
    var day = startDate.toLocaleString("default", { day: "2-digit" });

    var formattedDate = year + "-" + month + "-" + day;

    const years = range(1998, getYear(new Date()) + 1, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        if (data.disabled === true) {
            data.disabled = 1;
        } else {
            data.disabled = 0;
        }

        if (data.admin === true) {
            data.admin = 1;
        } else {
            data.admin = 0;
        }

        if (data.manager === true) {
            data.manager = 1;
        } else {
            data.manager = 0;
        }

        if (data.supervisor === true) {
            data.supervisor = 1;
        } else {
            data.supervisor = 0;
        }

        if (data.read_only === true) {
            data.read_only = 1;
        } else {
            data.read_only = 0;
        }

        const myImage = data.image[0];

        const myForm = { ...data, expiry_date: formattedDate, image: myImage };

        let formData = new FormData();

        Object.keys(myForm).forEach((key) => {
            formData.append(key, myForm[key]);
        });

        await api
            .post("/users", formData)
            .then((res) => {
                toast.success(t("done"));
                refetch();
                handleclose();
            })
            .catch((err) => {
                for (let i in err?.response?.data.error) {
                    toast.error(`${err?.response.data.error[i]}`);
                }

                // err?.response?.data?.error?.name?.map((el) => toast.error(el));
                // err?.response?.data?.error?.email?.map((el) => toast.error(el));
                // err?.response?.data?.error?.expiry_date?.map((el) =>
                //     toast.error(el)
                // );
                // err?.response?.data?.error?.password?.map((el) =>
                //     toast.error(el)
                // );
                // err?.response?.data?.error?.image?.map((el) => toast.error(el));
                // err?.response?.data?.error?.tracking_url?.map((el) =>
                //     toast.error(el)
                // );

                // toast.error(err.message);
            });
    };

    return (
        <form
            id="add-Employees"
            className="m-4 row"
            onSubmit={handleSubmit(onSubmit)}
        >
            <Col sm={6}>
                <MyInput
                    label={t("table.name")}
                    type="text"
                    registered={{
                        ...register("name"),
                    }}
                    error={errors?.name?.message && t("errors.reqName")}
                />
            </Col>
            <Col sm={6}>
                <MyInput
                    label={t("table.email")}
                    type="email"
                    registered={{
                        ...register("email"),
                    }}
                    error={errors.email?.message && t("errors.email")}
                />
            </Col>
            <Col sm={6}>
                <MyInput
                    label={t("table.image")}
                    type="file"
                    registered={{
                        ...register("image"),
                    }}
                    error={errors.image?.message}
                />
            </Col>
            <Col sm={6}>
                <Form.Group as={Col} controlId="from" className="selectDate">
                    <Form.Label className="form-label">
                        {t("table.date")}
                    </Form.Label>
                    <DatePicker
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div
                                style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <button
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                    className="btnSelectDate"
                                >
                                    {"<"}
                                </button>

                                <select
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) =>
                                        changeYear(value)
                                    }
                                    className="dropdownSelect"
                                >
                                    {years.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <select
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                    }
                                    className="dropdownSelect"
                                >
                                    {months.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>

                                <button
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                    className="btnSelectDate"
                                >
                                    {">"}
                                </button>
                            </div>
                        )}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy/MM/d"
                        placeholderText="Click to select a date"
                        withPortal
                        required
                    />
                </Form.Group>
            </Col>
            <Col sm={6}>
                <MyInput
                    label={t("password")}
                    type="password"
                    registered={{
                        ...register("password"),
                    }}
                    error={errors.password?.message && t("errors.pass")}
                />
            </Col>
            <Col sm={6}>
                <MyInput
                    label={t("passwordConfirmation")}
                    type="password"
                    registered={{
                        ...register("password_confirmation"),
                    }}
                    error={
                        errors.password_confirmation?.message &&
                        t("errors.passCon")
                    }
                />
            </Col>
            <Col sm={6}>
                <MyInput
                    label={t("trackingUrl")}
                    type="text"
                    registered={{
                        ...register("tracking_url"),
                    }}
                    error={errors.tracking_url?.message}
                />
            </Col>
            {/* <Col sm={6}>
        <MyInput
          label={t("trackingToken")}
          type="text"
          registered={{
            ...register("tracking_token"),
          }}
          error={errors.tracking_token?.message}
        />
      </Col> */}
            <Col sm={6}>
                <MyInput
                    label={t("tracking_ws")}
                    type="text"
                    registered={{
                        ...register("tracking_ws"),
                    }}
                    error={errors.tracking_ws?.message}
                />
            </Col>
            <Row>
                <Col>
                    <Form.Check
                        id="Admin"
                        type="checkbox"
                        label="Admin"
                        {...register("admin")}
                    />
                </Col>
                <Col>
                    <Form.Check
                        id="Manager"
                        type="checkbox"
                        label="Manager"
                        {...register("manager")}
                    />
                </Col>
                <Col>
                    <Form.Check
                        id="Supervisor"
                        type="checkbox"
                        label="Supervisor"
                        {...register("supervisor")}
                    />{" "}
                </Col>
                <Col>
                    <Form.Check
                        id="Read_only"
                        type="checkbox"
                        label="Read_only"
                        {...register("read_only")}
                    />
                </Col>
                <hr
                    className="my-4"
                    style={{
                        height: "1px",
                        border: "none",
                        backgroundColor: "grey  ",
                        boxShadow: " 0 0 9px 1px grey",
                    }}
                />
                <Col>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Disabled"
                        {...register("disabled")}
                    />
                </Col>
            </Row>
        </form>
    );
};

export default AddEmployeesForm;
