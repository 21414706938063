/* eslint-disable no-unused-vars */
import api from "./api";

// get Projects
export const getProjects = async ({ queryKey }) => {
  // get params from query
  const [_, pageNumber] = queryKey;

  // get data from api
  const data = await api.get("/projects", { params: { page: pageNumber } });

  return data?.data?.data;
};

//Employees
export const getUsers = async ({ queryKey }) => {
  const [_, pageNumber, perPage] = queryKey;

  const data = await api.get("/users", {
    params: { page: pageNumber, length: perPage },
  });

  return data?.data?.data;
};

// get reports
export const getReports = async ({ queryKey }) => {
  const [_, pageNumber, perPage] = queryKey;

  const data = await api.get("/reports", {
    params: { page: pageNumber, length: perPage },
  });

  return data?.data?.data;
};

// get categories
export const getCategories = async ({ queryKey }) => {
  // get params from query
  const [_, pageNumber] = queryKey;

  // get data from api
  const data = await api.get("/categories", { params: { page: pageNumber } });

  return data?.data?.data;
};

// get Companies
export const getCompanies = async ({ queryKey }) => {
  // get params from query
  const [_, pageNumber] = queryKey;

  // get data from api
  const data = await api.get("/companies", { params: { page: pageNumber } });

  return data?.data?.data;
};

// get me
export const getMe = async () => {
  // get data from api
  const data = await api.get("/me");

  console.log(data?.data?.data?.id, "ssdd");

  // console.log(data?.id, "ss");

  return data?.data?.data;
};

export const getCharts = async () => {
  // get data from api
  const data = await api.get("/report_status_group_date");
  return data?.data?.data;
};
