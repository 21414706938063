import "./Login.scss";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import MyButton from "../../components/MyButton/MyButton";
import LogoTahsen from "../../assets/icons/logo/logo.JPG";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import LangButton from "../../components/LangButton/LangButton";
import api from "../../api/api";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    await api
      .post("/auth/token", data)
      .then((res) => {
        toast.success(t("done"));

        const token = res?.data.access_token;

        console.log(res?.data, "res.data");

        localStorage.setItem("token", token);

        navigate("/");
      })
      .catch((err) => {
        err?.response?.data?.message && toast.error(err.response.data.message);
        console.log(err, "err");
        // toast.error(err.message);
      });
  };

  const { t } = useTranslation();

  return (
    <div className="login">
      <LangButton className="floatLangButton" />
      <div className="wrapper">
        <div className="logo">
          <img src={LogoTahsen} width="180px" height="180px" alt="logo" />
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="mt-4 text-center">{t("welcomeLogin")}</h2>
          <span className="d-block mt-4 text-center">{t("titleLogin")}</span>
          <Form.Group className="mb-4 mt-5 fm-group">
            <Form.Control
              {...register("username", {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
              type="email"
              className="form-control"
              id="email"
              aria-describedby="emailHelp"
              placeholder=" "
            />
            <Form.Label>{t("table.email")}</Form.Label>
            {errors.username && (
              <Form.Text id="emailHelp" className="text-danger">
                {t("valid.userName")}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-4 fm-group">
            <Form.Control
              {...register("password", { required: true })}
              type="password"
              className="form-control"
              id="password"
              aria-describedby="passwordHelp"
              placeholder=" "
            />
            <Form.Label>{t("password")}</Form.Label>
            {errors.password && (
              <Form.Text id="passwordHelp" className="text-danger">
                {t("valid.password")}
              </Form.Text>
            )}
          </Form.Group>
          {/* <Link to="/forget-password">{t("forgetPassword")}</Link> */}
          <MyButton text={t("login")} cln="btn-login my-4" />
          {/* <span className="d-block text-center">
                        <span className="mx-2">{t("dontHaveAccount")}</span>
                        <Link to="/signup">{t("createAccount")}</Link>
                    </span> */}
        </Form>
      </div>
    </div>
  );
};

export default Login;
