// import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from "react-router-dom";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
// import 'react-pro-sidebar/dist/css/styles.css';
import { SidebarContxt } from "../../contexts/sidebarContext";
import { useContext } from "react";
import { LangContext } from "../../contexts/langContext";
import LogoImage from "../../assets/icons/logo/logo2.png";

import "./Sidebar.scss";

import { ReactComponent as HomeIcon } from "../../assets/icons/sidebar/home.svg";
import { ReactComponent as EmployeesIcon } from "../../assets/icons/sidebar/employees.svg";
import { ReactComponent as ReportsIcon } from "../../assets/icons/home/reports.svg";
import { ReactComponent as ProjectsIcon } from "../../assets/icons/sidebar/projects.svg";
import { ReactComponent as CompaniesIcon } from "../../assets/icons/sidebar/companies.svg";
import { ReactComponent as PermissionsIcon } from "../../assets/icons/sidebar/permissions.svg";
import { ReactComponent as CategoryIcon } from "../../assets/icons/category.svg";

import { useTranslation } from "react-i18next";

const Sidebar = ({ children }) => {
  const { toggled, collapsed, setToggled } = useContext(SidebarContxt);
  const { lang } = useContext(LangContext);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <ProSidebar
      breakPoint="md"
      toggled={toggled}
      collapsed={collapsed}
      rtl={lang === "ar" ? true : false}
      onToggle={() => setToggled(!toggled)}
    >
      <SidebarHeader className="text-center">
        <img
          src={LogoImage}
          alt="logo"
          width="140px"
          className={`${collapsed ? "logoSmall" : ""} logo`}
        />
        {/* <h1>Tahsen</h1> */}
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="round">
          <MenuItem
            icon={<HomeIcon />}
            active={pathname === "/" ? true : false}
          >
            {t("home")}
            <NavLink to="/" />
          </MenuItem>
          <MenuItem
            icon={<EmployeesIcon />}
            active={pathname === "/employees" ? true : false}
          >
            {t("emp")}
            <Link to="employees" />
          </MenuItem>
          <MenuItem
            icon={<CompaniesIcon />}
            active={pathname === "/companies" ? true : false}
          >
            {t("Companies")}
            <Link to="companies" />
          </MenuItem>
          <MenuItem
            icon={<ReportsIcon />}
            active={pathname === "/reports" ? true : false}
          >
            {t("reports")}

            <Link to="reports" />
          </MenuItem>
          <MenuItem
            icon={<ProjectsIcon />}
            active={pathname === "/projects" ? true : false}
          >
            {t("projects")}

            <Link to="projects" />
          </MenuItem>
          <MenuItem
            icon={<CategoryIcon />}
            active={pathname === "/category" ? true : false}
          >
            {t("table.category")}

            <Link to="category" />
          </MenuItem>

          <MenuItem
            icon={<PermissionsIcon />}
            active={pathname === "/permissions" ? true : false}
          >
            {t("table.permissions")}

            <Link to="permissions" />
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter className="text-center">tahsen: 1.0.0 V</SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;
